import * as React from 'react';
import "./QuestionList.css?__remix_sideEffect__";

const QuestionList = ({ questions }) => {
  if (!questions) {
    console.error('No questions provided to QuestionList component');
  }

  const getAnswerElement = (question) => {
    if (!question.answer) {
      return null;
    }

    return (
      <p className='answer copy'>{question.answer}</p>);

  };

  return (
    <div className='QuestionList'>
      <ul className='questions'>
        {questions.map((question, index) => {
          return (
            <li key={`question-${index}`} className='question-item'>
              <h3 className='query headline headline--sm headline--italic'>{question.questionCopy}</h3>
              {getAnswerElement(question)}
            </li>);

        })}
      </ul>
    </div>);

};
export default QuestionList;